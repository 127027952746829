import React from 'react';
import AniLink from 'gatsby-plugin-transition-link/AniLink';

const Next = ({ data, lang }) => {
  if (!data.name && !data.link) {
    return null;
  }

  const renderTexts = language => {
    switch (language) {
      case 'en':
        return {
          next: 'Next in the process',
          find: 'Find out about',
        };
      case 'de':
        return {
          next: 'Der Nächste im',
          find: 'Erfahren Sie mehr über',
        };
      case 'bg':
        return {
          next: 'Към следващия процес',
          find: 'Разберете повече за',
        };
      default:
        return {
          next: '',
          find: '',
        };
    }
  };

  return (
    <section className="pt-lg-12 pt-mg-17 pb-7 pb-md-12 pb-xl-18 pb-mg-27">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-mg-10">
            <div className="bg-primary d-md-flex align-items-center justify-content-between p-6 py-md-8 py-lg-12 px-md-5 px-lg-10 px-xl-17">
              <div>
                <p className="text-title text-white">
                  {renderTexts(lang).next}
                </p>

                <p className="text-mega text-white mb-7 mb-md-0">{data.name}</p>
              </div>

              <AniLink
                cover
                direction="up"
                bg="#092431"
                to={`${lang}/processes${data.link}`}
                className="btn btn-outline-white d-block d-md-inline-block text-white rounded-lg"
              >
                <span className="d-none d-md-inline">
                  {renderTexts(lang).find} {data.name}
                </span>
                <i className="d-inline far fa-long-arrow-right ml-md-4"></i>
              </AniLink>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Next;
