import React from 'react';
import { graphql } from 'gatsby';

//Components
import Layout from '../components/layout';
import SEO from '../components/seo';
import Intro from '../components/intro';
import SectionText from '../components/section-text';
import Blocks from '../components/blocks';
import Next from '../components/next';

export const SingleProcessTemplate = ({ data, lang }) => {
  return (
    <>
      <Intro data={data.intro} />

      <SectionText data={data.information} />

      <Blocks data={data.subtypes} />

      <Next data={data.nextType} lang={lang} />
    </>
  );
};

const SingleProcess = ({ data, pageContext }) => {
  return (
    <Layout lang={pageContext.lang}>
      <SEO
        title={data.processesJson.languages[pageContext.lang].metaData.title}
        description={
          data.processesJson.languages[pageContext.lang].metaData.description
        }
      />

      <SingleProcessTemplate
        data={data.processesJson.languages[pageContext.lang]}
        lang={pageContext.lang}
      />
    </Layout>
  );
};

export const query = graphql`
  query SINGLE_PROCESS_PAGE($title: String!) {
    processesJson(title: { eq: $title }) {
      title
      languages {
        en {
          metaData {
            title
            description
          }
          intro {
            media {
              childImageSharp {
                fluid(maxWidth: 1800) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
              publicURL
            }
            thumbnailVideo
            fullVideo
            text
            title
          }
          information {
            text
            title
          }
          subtypes {
            entry
            image {
              childImageSharp {
                fluid(maxWidth: 696, maxHeight: 464) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
              publicURL
            }
            title
          }
          nextType {
            link
            name
          }
        }
        de {
          metaData {
            title
            description
          }
          intro {
            media {
              childImageSharp {
                fluid(maxWidth: 1800) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
              publicURL
            }
            thumbnailVideo
            fullVideo
            text
            title
          }
          information {
            text
            title
          }
          subtypes {
            entry
            image {
              childImageSharp {
                fluid(maxWidth: 696, maxHeight: 464) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
              publicURL
            }
            title
          }
          nextType {
            link
            name
          }
        }
        bg {
          metaData {
            title
            description
          }
          intro {
            media {
              childImageSharp {
                fluid(maxWidth: 1800) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
              publicURL
            }
            thumbnailVideo
            fullVideo
            text
            title
          }
          information {
            text
            title
          }
          subtypes {
            entry
            image {
              childImageSharp {
                fluid(maxWidth: 696, maxHeight: 464) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
              publicURL
            }
            title
          }
          nextType {
            link
            name
          }
        }
      }
    }
  }
`;

export default SingleProcess;
